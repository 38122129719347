<template>
  <div>
    <loading v-if="cargando" />
    <div class="container mt-5">
      <div class="row" v-if="ofertas.length > 0">
        <div
          class="col-md-6 mt-4"
          v-for="(oferta, i) in ofertas"
          :key="oferta.id"
        >
          <div class="info-box">
            <span class="info-box-icon" :class="colores_ofertas[i]"
              ><i class="fas fa-truck-moving"></i
            ></span>

            <div class="info-box-content" style="font-size: 1.1em">
              <h4 class="info-box-text font-weight-bold">
                {{ oferta.solicitud.sitio.nombre }}
              </h4>
              <span class="info-box-text mt-2"
                >Cantidad Vehículos:
                <strong>{{ oferta.cantidad }}</strong></span
              >
              <span class="info-box-text mt-2"
                >Postulados:
                <strong>{{ oferta.postulaciones.length }}</strong></span
              >
              <span class="info-box-text mt-2"
                >Vencimiento:
                <strong>{{ oferta.fecha_vencimiento }}</strong></span
              >
              <span
                class="info-box-text mt-2"
                v-if="oferta.producto_liquido !== null"
                >Producto Liquido:
                <strong>{{ oferta.producto_liquido.nombre }}</strong></span
              >
              <span class="info-box-text mt-2" v-else>Producto Liquido:</span>
              <div class="row">
                <span
                  class="badge mt-2 col-md-6"
                  :class="
                    oferta.tipo_ruta == 1 ? 'badge-info' : 'badge-warning'
                  "
                  >{{ oferta.nTipoRuta }}</span
                >
                <span class="badge badge-dark mt-2 col-md-6">
                  {{ oferta.producto ? oferta.producto.nombre : "" }}
                </span>
              </div>
            </div>
            <div class="info-box-footer">
              <a
                href="#"
                data-toggle="modal"
                data-target="#modal-form"
                class="info-box-footer text-gray-dark"
                @click="setOferta(oferta)"
              >
                <i class="fa fa-plus-circle"></i>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="alert alert-warning text-center" v-else>
        <h5 class="text-center">
          <i class="icon fas fa-exclamation-triangle"></i>
          Atención!
        </h5>

        No se encontraron adicionales ofertados para su empresa.
      </div>
    </div>

    <div
      class="modal fade"
      id="modal-form"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
      data-backdrop="static"
      data-keyboard="false"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">POSTULAR OFERTA</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div
              class="row m-3 border"
              v-for="postulacion in postulaciones"
              :key="postulacion.id"
            >
              <div class="col-md-3">
                <label>Vehículo</label>
                <p>{{ postulacion.vehiculo.placa }}</p>
              </div>
              <div class="col-md-3">
                <label v-if="postulacion.remolque != null">Remolque</label>
                <p>
                  {{
                    postulacion.remolque != null
                      ? postulacion.remolque.placa
                      : ""
                  }}
                </p>
              </div>
              <div class="col-md-5">
                <label>Conductor</label>
                <p>
                  {{
                    "(" +
                    postulacion.conductor.numero_documento +
                    ") - " +
                    postulacion.conductor.nombres +
                    " " +
                    postulacion.conductor.apellidos
                  }}
                </p>
              </div>
            </div>

            <div
              class="row mt-3 mb-3 border"
              v-if="postulaciones.length < oferta.cantidad"
            >
              <div class="col-md-3">
                <label>Vehículo</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="formActualizar.vehiculo.placa"
                  :class="
                    $v.formActualizar.vehiculo.id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="
                    buscarVehiculos(oferta.tipo_ruta, oferta.tipo_vehiculo_id)
                  "
                />
              </div>
              <div class="col-md-3">
                <label>Remolque</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="formActualizar.remolque.placa"
                  v-if="oferta.tipo_vehiculo_id == 1"
                  :class="
                    $v.formActualizar.remolque.id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="buscarRemolques()"
                />
              </div>
              <div class="col-md-5">
                <label>Conductor</label>
                <input
                  type="text"
                  class="form-control form-control-sm"
                  v-model="formActualizar.conductor.numero_documento"
                  :class="
                    $v.formActualizar.conductor.id.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="buscarConductores()"
                />
                {{ formActualizar.conductor.nombres }}
                {{ formActualizar.conductor.apellidos }}
              </div>
              <div class="col-md-1" v-if="falgValidando">
                <div class="spinner-border text-dark" role="status"></div>
              </div>
              <div class="col-md-1" v-else>
                <button
                  type="button"
                  class="btn btn-primary mt-4"
                  @click="savePostulacion()"
                  v-if="!$v.formActualizar.$invalid"
                >
                  <i class="fas fa-save"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import Loading from "../../../../components/Loading";
export default {
  name: "OfertaAdicionalesTrans",
  components: {
    Loading,
  },
  data() {
    return {
      cargando: false,
      falgValidando: false,
      ofertas: [],
      colores_ofertas: [
        "bg-frontera text-white",
        "bg-info",
        "bg-olive",
        "bg-navy",
        "bg-warning",
        "bg-gray",
        "bg-success",
        "bg-secondary",
        "bg-danger",
        "bg-maroon",
      ],
      oferta: {},
      formActualizar: {
        vehiculo: {},
        remolque: {},
        conductor: {},
      },
      postulaciones: [],
      distancia_vh: null,
    };
  },

  validations() {
    if (this.oferta.tipo_vehiculo_id == 1) {
      return {
        formActualizar: {
          vehiculo: {
            id: {
              required,
            },
          },
          remolque: {
            id: {
              required,
            },
          },
          conductor: {
            id: {
              required,
            },
          },
        },
      };
    } else {
      return {
        formActualizar: {
          vehiculo: {
            id: {
              required,
            },
          },
          remolque: {
            id: {},
          },
          conductor: {
            id: {
              required,
            },
          },
        },
      };
    }
  },
  methods: {
    getIndex() {
      this.$parent.cargando = true;
      axios.get("/api/hidrocarburos/ofertaAdicional").then((response) => {
        if (this.$store.getters.getUser.empresas.length > 0) {
          this.ofertas = response.data;
        } else {
          this.ofertas = response.data.data;
        }

        this.$parent.cargando = false;
      });
    },

    async setOferta(oferta) {
      this.$parent.cargando = true;
      this.oferta = oferta;
      await this.getPostulaciones();
      this.$parent.cargando = false;
    },

    async getPostulaciones() {
      let params = {
        oferta_adicional_id: this.oferta.id,
        empresa_id: this.$store.getters.getUser.empresas[0].id,
      };

      await axios
        .get("/api/hidrocarburos/ofertaAdicional/indexPostulacion", {
          params,
        })
        .then((response) => {
          this.postulaciones = response.data;
        });
    },

    async savePostulacion() {
      this.cargando = true;
      this.formActualizar.oferta_adicional_id = this.oferta.id;
      this.formActualizar.solicitud_id = this.oferta.solicitud_id;
      this.formActualizar.distancia_vh = this.distancia_vh;
      this.formActualizar.empresa_id =
        this.$store.getters.getUser.empresas.length > 0
          ? this.$store.getters.getUser.empresas[0].id
          : null;
      this.formActualizar.vehiculo_id = this.formActualizar.vehiculo.id;
      this.formActualizar.remolque_id = this.formActualizar.remolque
        ? this.formActualizar.remolque.id
        : null;
      this.formActualizar.conductor_id = this.formActualizar.conductor.id;

      await axios
        .post("api/hidrocarburos/ofertaAdicional/postular", this.formActualizar)
        .then(async () => {
          this.getPostulaciones();
          this.getIndex();
          this.$swal({
            icon: "success",
            title: "La postulación se guardó exitosamente...",
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });

          this.formActualizar = {
            vehiculo: {},
            remolque: {},
            conductor: {},
          };
          this.cargando = false;
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error, vuelva a intentarlo..." + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        });
    },

    async buscarVehiculos(tipo_ruta, tipo_vehiculo) {
      this.falgValidando = true;
      let dato = this.formActualizar.vehiculo.placa;
      let res = await this.validarStatus(dato, "V");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.falgValidando = false;
          /* if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) { */
          this.formActualizar.vehiculo = {};
          return false;
          //}
        }

        if (res.viaje.length > 0) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        //Validar si ya se encuentra con una postulacion
        if (res.postulacion.length > 0) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        if (res.postulacion_adicionales.length > 0) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehiculo ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        //Validar si el vehículo tiene peso asignado.
        if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El vehículo no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Vehiculos`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.vehiculo = {};
          return false;
        }

        //Valida el tipo de vehiculo
        if (res[0].tipo_vehiculo_id != tipo_vehiculo) {
          this.formActualizar.vehiculo = {};
          this.$swal({
            icon: "error",
            title: `El tipo de vehículo no coincide.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        }

        //Valida el modelo para el trayecto de la ruta larga
        if (tipo_ruta == 2) {
          if (res.modelo) {
            this.formActualizar.vehiculo = res[0];
          } else {
            this.formActualizar.vehiculo = {};
            this.$swal({
              icon: "error",
              title:
                "El vehículo no cumple con las condiciones para ruta larga",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          }
        }

        //Valida reporte satelital del vehiculo
        if (res.ultimoRegistro) {
          if (res.ultimoRegistro.error) {
            this.formActualizar.vehiculo = {};
            this.$swal({
              icon: "error",
              title: `Error GPS: ${res.ultimoRegistro.error.msg}`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.falgValidando = false;
            return false;
          } else if (res.ultimoRegistro.success) {
            this.formActualizar.vehiculo = res[0];
            this.$swal({
              icon: "success",
              title: `${res.ultimoRegistro.success.msg} Distancia: ${res.ultimoRegistro.success.distance} Km`,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
            this.distancia_vh = res.ultimoRegistro.success.distance;
            this.falgValidando = false;
            return true;
          }
        }
        this.formActualizar.vehiculo = res[0];
        this.falgValidando = false;
        return true;
      } else {
        this.$swal({
          icon: "error",
          title:
            "El vehículo digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.falgValidando = false;
        this.formActualizar.vehiculo = {};
        return false;
      }
    },

    async buscarRemolques() {
      this.falgValidando = true;
      let dato = this.formActualizar.remolque.placa;
      let res = await this.validarStatus(dato, "R");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.falgValidando = false;
          /* if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) { */
          this.formActualizar.remolque = {};
          return false;
          //}
        }

        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Relmoques`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.remolque = {};
          return false;
        }

        if (res.viaje.length > 0) {
          this.formActualizar.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion.length > 0) {
          this.formActualizar.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion_adicionales.length > 0) {
          this.formActualizar.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        } //Validar si el rermolque tiene peso asignado.
        else if (res[0].peso_vacio == null || res[0].peso_vacio == 0) {
          this.formActualizar.remolque = {};
          this.$swal({
            icon: "error",
            title: `El remolque no tiene peso asignado.`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else {
          this.formActualizar.remolque = res[0];
        }
        this.formActualizar.remolque = res[0];
      } else {
        this.$swal({
          icon: "error",
          title:
            "El remolque digitado NO se encuentra registrado, pruebe con otra placa.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.formActualizar.remolque = {};
      }

      this.falgValidando = false;
    },

    async buscarConductores() {
      this.falgValidando = true;
      let dato = this.formActualizar.conductor.numero_documento;
      let res = await this.validarStatus(dato, "C");
      if (res && res.length != 0) {
        if (res.validacion.error) {
          await this.msgDocumentacion(res);
          this.falgValidando = false;
          /* if (
            res.validacion.error.documentos.obligatorios !== undefined ||
            res.validacion.error.documentos.vencidos !== undefined ||
            res.validacion.error.documentos.no_verificados !== undefined
          ) { */
          this.formActualizar.conductor = {};
          return false;
          //}
        }

        if (res["info"] == false) {
          this.$swal({
            icon: "error",
            title: `Hacen falta campos por diligenciar en el formulario de creacion de Conductores`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
          this.formActualizar.conductor = {};
          return false;
        }

        if (res.viaje.length > 0) {
          this.formActualizar.conductor = {};
          this.$swal({
            icon: "error",
            title: `El conductor se encuentra activo dentro de un viaje`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion.length > 0) {
          this.formActualizar.conductor = {};
          this.$swal({
            icon: "error",
            title: `El conductor cuenta con una postulacion activa`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        } else if (res.postulacion_adicionales.length > 0) {
          this.formActualizar.conductor = {};
          this.$swal({
            icon: "error",
            title: `El conductor ya está en un proceso de postulacion de adicionales, espere a que este finalice`,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
          });
          this.falgValidando = false;
          return false;
        } else {
          this.formActualizar.conductor = res[0];
        }
        this.formActualizar.conductor = res[0];
        this.falgValidando = false;
      } else {
        this.$swal({
          icon: "error",
          title:
            "El conductor digitado NO se encuentra registrado, pruebe con otro documento.",
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 4000,
          timerProgressBar: true,
        });
        this.formActualizar.conductor = {};
      }
      this.falgValidando = false;
    },

    async validarStatus(dato, tipo) {
      let me = this;
      let url = "";
      let entidad = null;
      if (tipo == "C") {
        entidad = "Conductor";
        url = "api/hidrocarburos/postulaciones/listaConductores";
      } else if (tipo == "R") {
        entidad = "Remolque";
        url = "api/hidrocarburos/postulaciones/listaRemolques";
      } else if (tipo == "V") {
        entidad = "Vehiculo";
        url = "api/hidrocarburos/postulaciones/listaVehiculos";
      }

      let params = {
        entidad,
        dato,
        fecha: this.oferta.solicitud.fecha,
        sitio_id: this.oferta.solicitud.sitio.id,
        tipo_distancia: 2,
      };

      var res = null;
      await axios
        .get(url, {
          params,
        })
        .then((response) => {
          res = response.data;
        })
        .catch(function (e) {
          me.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
      return res;
    },

    async msgDocumentacion(res) {
      if (res.validacion.error) {
        let erroHtml = "<div>";
        if (res.validacion.error.documentos) {
          if (res.validacion.error.documentos.obligatorios) {
            erroHtml += "<b>Pendiente:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.obligatorios.join("<br>");
          }
          if (res.validacion.error.documentos.vencidos) {
            erroHtml += "<br><b>Vencidos:</b><br>";
            for (
              let i = 0;
              i < res.validacion.error.documentos.vencidos.length;
              i++
            ) {
              erroHtml +=
                res.validacion.error.documentos.vencidos[i].documento +
                " - " +
                res.validacion.error.documentos.vencidos[i].fecha_vencimiento +
                "<br>";
            }
          }
          if (res.validacion.error.documentos.no_verificados) {
            erroHtml += "<br><b>No Verificados:</b><br>";
            erroHtml +=
              res.validacion.error.documentos.no_verificados.join("<br>");
          }
          erroHtml += "</div>";
          await this.$swal({
            html: erroHtml,
            showCancelButton: false,
            focusConfirm: true,
            icon: "error",
            title: "Aviso en documentacion",
            showConfirmButton: false,
            showCloseButton: true,
            footer:
              "<small style='text-align:center'><b>Desde el 1 de octubre se aplicaran las restricciones</b>, por favor tenga al dia la documentacion de sus vehiculos, para mas informacion comuniquese con la mesa de ayuda al siguiente numero <b>3176431376 Opción 3</b></small>",
          });
        } else {
          this.$swal({
            icon: "error",
            title: res.validacion.error.msg,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 4000,
            timerProgressBar: true,
          });
        }
      }
    },
  },
  mounted() {
    this.getIndex();
  },
};
</script>
